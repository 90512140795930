import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import "./highlight.css";
import Header from "./header";
import championMapping from "./mapping"; // 챔피언 이름을 이미지로 매핑하는 파일

function Highlight() {
  const [highlightData, setHighlightData] = useState([]);
  const [recordData, setRecordData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define position order for sorting
  const positionOrder = ["top", "jungle", "mid", "adc", "sup"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const highlightSnapshot = await getDocs(collection(db, "highlight"));
        const highlights = highlightSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const recordSnapshot = await getDocs(collection(db, "record"));
        const records = recordSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const groupedRecords = records.reduce((acc, record) => {
          const { gameId } = record;
          if (!acc[gameId]) {
            acc[gameId] = { blue: [], red: [], winOrLose: {}, totalKills: { blue: 0, red: 0 } };
          }

          const side = record.side;
          acc[gameId][side].push(record);
          acc[gameId].winOrLose[side] = record.winOrLose;
          acc[gameId].totalKills[side] += parseInt(record.kill, 10) || 0;

          return acc;
        }, {});

        // Sort players by position
        Object.keys(groupedRecords).forEach((gameId) => {
          ["blue", "red"].forEach((side) => {
            groupedRecords[gameId][side].sort(
              (a, b) => positionOrder.indexOf(a.position) - positionOrder.indexOf(b.position)
            );
          });
        });

        setHighlightData(highlights);
        setRecordData(groupedRecords);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("데이터를 가져오는 중 문제가 발생했습니다.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Header />
      <div className="highlightComponent">
        {highlightData
          .sort((a, b) => parseInt(b.gameId) - parseInt(a.gameId)) // gameId 기준 내림차순 정렬
          .map((highlight) => (
            <div key={highlight.id} className="game-block">
              <p className="gameid">게임ID: {highlight.gameId}</p>

              {/* 하이라이트 비디오 */}
              <div className="videoContainer">
  <iframe
    src={highlight.src || 'https://www.youtube.com/embed/defaultVideoID'} // 기본 비디오 URL 추가
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>


              {/* 게임 기록 테이블 */}
              {recordData[highlight.gameId] ? (
                <div className="game-record">
                  <div className="game-block-child">
                    <div className="blueteam">
                      <p
                        style={{
                          backgroundColor:
                            recordData[highlight.gameId].winOrLose.blue === "win" ? "#007bff" : "#ff3300",
                          color: "white",
                          padding: "5px",
                        }}
                      >
                        {recordData[highlight.gameId].winOrLose.blue === "win" ? "승리" : "패배"}{" "}
                        <span className="blueTeamKill">{recordData[highlight.gameId].totalKills.blue}</span>
                      </p>
                      <table>
                        <thead>
                          <tr>
                            <th>CS</th>
                            <th>K</th>
                            <th>D</th>
                            <th>A</th>
                            <th className="sideTh">Blue</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recordData[highlight.gameId].blue.map((player) => (
                            <tr key={player.id}>
                              <td>{player.cs}</td>
                              <td>{player.kill}</td>
                              <td>{player.death}</td>
                              <td>{player.assist}</td>
                              <td className="blueimgtd">
                                <p>{player.player}</p>
                                <div className="cropping">
                                  <img
                                    src={
                                      championMapping[player.champion]
                                        ? `https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[player.champion]}.png`
                                        : "https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/Unknown.png"
                                    }
                                    alt={player.champion}
                                    className="championImg"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="redteam">
                      <p
                        style={{
                          backgroundColor:
                            recordData[highlight.gameId].winOrLose.red === "win" ? "#007bff" : "#ff3300",
                          color: "white",
                          padding: "5px",
                        }}
                      >
                        <span className="redTeamKill">{recordData[highlight.gameId].totalKills.red}</span>{" "}
                        {recordData[highlight.gameId].winOrLose.red === "win" ? "승리" : "패배"}
                      </p>
                      <table>
                        <thead>
                          <tr>
                            <th className="sideTh">Red</th>
                            <th>K</th>
                            <th>D</th>
                            <th>A</th>
                            <th>CS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recordData[highlight.gameId].red.map((player) => (
                            <tr key={player.id}>
                              <td className="redimgtd">
                                <div className="cropping">
                                  <img
                                    src={
                                      championMapping[player.champion]
                                        ? `https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[player.champion]}.png`
                                        : "https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/Unknown.png"
                                    }
                                    alt={player.champion}
                                    className="championImg"
                                  />
                                </div>
                                <p>{player.player}</p>
                              </td>
                              <td>{player.kill}</td>
                              <td>{player.death}</td>
                              <td>{player.assist}</td>
                              <td>{player.cs}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <p>해당 게임 기록이 없습니다.</p> // 기록이 없는 경우 메시지 표시
              )}
            </div>
          ))}
      </div>
    </>
  );
}

export default Highlight;
