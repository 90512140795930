// Header.js
import React, { useState } from "react";
import './header.css'; // 스타일을 위한 CSS 파일
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import swggLogo from '../img/swggLogo.png';

function Header() {
  const location = useLocation();  // 현재 URL 경로를 가져옵니다
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // 메뉴 열기/닫기 토글 함수
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // 현재 페이지에 맞는 문구를 반환하는 함수
  const getHeaderTip = () => {
    switch (location.pathname) {
      case '/':
        return "챔피언 이름을 클릭하면 op.gg로 이동합니다";
      case '/team':
        return "목록에 없는 플레이어는 추가 기능을 통해 추가할 수 있습니다";
      case '/ranking':
        return "플레이어 검색 기능을 이용해 원하는 플레이어의 기록을 쉽게 찾을 수 있습니다";
      case '/history':
        return "최근 플레이 전적이 상단에 표시됩니다";
      case '/champion':
        return "챔피언을 누르면 전적이 표시됩니다"
      case '/winrate':
        return "팀으로 만났을 때 / 적으로 만났을 때의 승률을 확인할 수 있습니다"
      case '/highlight':
        return "주인장이 엄선한 하이라이트를 볼 수 있습니다"
      case '/nakhoon':
        return "송낙훈 남은 챔피언 백과사전"
      default:
        return "챔피언 이름을 클릭하면 op.gg로 이동합니다";
    }
  };

  return (
    <>
    <header className="header">
        <div className="header-par">
            <Link to="/"><img src={swggLogo} alt="Example" width="64px"/></Link>
            <Link to="/"><p>플레이어</p></Link>
            <Link to="/history"><p>전적</p></Link>
            <Link to="/team"><p>팀 구성</p></Link>
            <Link to="/ranking"><p>플레이어 통계</p></Link>
            <Link to="/champion"><p>챔피언 통계</p></Link>
            <Link to="/winrate"><p>팀&상대 승률</p></Link>
            <Link to="/highlight"><p>하이라이트</p></Link>
            <Link to="/nakhoon"><p>송낙훈</p></Link>
            </div>
    <p className="headerTip">{getHeaderTip()}</p>
    </header>

<header className="mobile-header">
<div className="header-par">
  <Link onClick={toggleMenu}><img src={swggLogo} alt="SWGG Logo" width="64px" /></Link>
  {/* 모바일에서만 토글되는 메뉴 */}
  <div className={`menu ${isMenuOpen ? 'open' : 'closed'}`}>
              <Link to="/"><p>플레이어</p></Link>
              <Link to="/history"><p>전적</p></Link>
              <Link to="/team"><p>팀 구성</p></Link>
              <Link to="/ranking"><p>플레이어 통계</p></Link>
              <Link to="/champion"><p>챔피언 통계</p></Link>
              <Link to="/winrate"><p>팀&상대 승률</p></Link>
              <Link to="/highlight"><p>하이라이트</p></Link>
              <Link to="/nakhoon"><p>송낙훈</p></Link>
  </div>
  <p className="headerTip">상단 로고 클릭 시 바로가기 메뉴가 열립니다</p>
</div>
</header>
</>
  );
}

export default Header;
