import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./upload.css";

function Upload() {
  const [gameId, setGameId] = useState("");
  const [src, setSrc] = useState("");
  const [description, setDescription] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!gameId || !src || !description) {
      setStatusMessage("모든 필드를 채워주세요!");
      return;
    }

    try {
      await addDoc(collection(db, "highlight"), {
        gameId,
        src,
        description,
        timestamp: new Date().toISOString(),
      });
      setStatusMessage("업로드 성공!");
      setGameId("");
      setSrc("");
      setDescription("");
    } catch (error) {
      console.error("Error uploading highlight: ", error);
      setStatusMessage("업로드 실패!");
    }
  };

  return (
    <div className="uploadComponent">
      <h2>하이라이트 업로드</h2>
      <form onSubmit={handleUpload} className="uploadForm">
        <div className="formGroup">
          <label htmlFor="gameId">게임 ID</label>
          <input
            type="text"
            id="gameId"
            value={gameId}
            onChange={(e) => setGameId(e.target.value)}
            placeholder="게임 ID를 입력하세요"
          />
        </div>
        <div className="formGroup">
          <label htmlFor="src">영상 URL</label>
          <input
            type="text"
            id="src"
            value={src}
            onChange={(e) => setSrc(e.target.value)}
            placeholder="유튜브 URL을 입력하세요"
          />
        </div>
        <div className="formGroup">
          <label htmlFor="description">설명</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="설명을 입력하세요"
          ></textarea>
        </div>
        <button type="submit">업로드</button>
        {statusMessage && <p className="statusMessage">{statusMessage}</p>}
      </form>
    </div>
  );
}

export default Upload;
