import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import './ranking.css';
import Header from "./header";

function Ranking() {
    const [playerData, setPlayerData] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('경기');
    const [ascending, setAscending] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "record"));
                const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                const playerStats = items.reduce((acc, item) => {
                    const { player, kill, death, assist, winOrLose, cs, champion } = item;
                    if (!acc[player]) {
                        acc[player] = {
                            totalKills: 0,
                            totalDeaths: 0,
                            totalAssists: 0,
                            totalWins: 0,
                            totalLosses: 0,
                            totalGames: 0,
                            totalCS: 0, // CS 초기화
                            champions: {} // 플레이한 챔피언 초기화
                        };
                    }
                    acc[player].totalKills += parseInt(kill, 10) || 0;
                    acc[player].totalDeaths += parseInt(death, 10) || 0;
                    acc[player].totalAssists += parseInt(assist, 10) || 0;
                    acc[player].totalCS += parseInt(cs, 10) || 0; // CS 누적
                    acc[player].totalGames += 1;
                    if (winOrLose === "win") {
                        acc[player].totalWins += 1;
                    } else {
                        acc[player].totalLosses += 1;
                    }

                    // 챔피언 추가
                    if (champion) {
                        if (!acc[player].champions[champion]) {
                            acc[player].champions[champion] = true; // 중복된 챔피언 방지
                        }
                    }

                    return acc;
                }, {});

                // 데이터 가공 (평균값 계산)
                const processedData = Object.entries(playerStats).map(([player, stats]) => {
                    const kdaRatio = stats.totalDeaths > 0
                        ? ((stats.totalKills + stats.totalAssists) / stats.totalDeaths).toFixed(2)
                        : "Perfect";
                    const winRate = stats.totalGames > 0 ? ((stats.totalWins / stats.totalGames) * 100).toFixed(1) : 0;

                    const averageKills = (stats.totalKills / stats.totalGames).toFixed(1);
                    const averageDeaths = (stats.totalDeaths / stats.totalGames).toFixed(1);
                    const averageAssists = (stats.totalAssists / stats.totalGames).toFixed(1);
                    const averageCS = (stats.totalCS / stats.totalGames).toFixed(1);

                    // 플레이한 챔피언 수 계산
                    const championCount = Object.keys(stats.champions).length;

                    return {
                        player,
                        kills: stats.totalKills,
                        deaths: stats.totalDeaths,
                        assists: stats.totalAssists,
                        kdaRatio,
                        winRate,
                        wins: stats.totalWins,
                        losses: stats.totalLosses,
                        games: stats.totalGames,
                        averageKills,
                        averageDeaths,
                        averageAssists,
                        averageCS,
                        championCount // 추가된 챔피언 수
                    };
                });

                setPlayerData(processedData);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    const criteriaMapping = {
        '킬': 'kills',
        '데스': 'deaths',
        '어시스트': 'assists',
        'KDA': 'kdaRatio',
        '승률': 'winRate',
        '승': 'wins',
        '패': 'losses',
        '경기': 'games',
        '평균킬': 'averageKills',
        '평균데스': 'averageDeaths',
        '평균어시스트': 'averageAssists',
        'CS': 'averageCS',
        '챔피언': 'championCount' // 챔피언 수에 대한 기준 추가
    };

    const sortedData = [...playerData].sort((a, b) => {
        const criterion = criteriaMapping[sortCriteria];
        let comparison = 0;

        switch (criterion) {
            case 'kills':
                comparison = b.kills - a.kills;
                break;
            case 'deaths':
                comparison = a.deaths - b.deaths;
                break;
            case 'assists':
                comparison = b.assists - a.assists;
                break;
            case 'kdaRatio':
                comparison = parseFloat(b.kdaRatio) - parseFloat(a.kdaRatio);
                break;
            case 'winRate':
                comparison = b.winRate - a.winRate;
                break;
            case 'wins':
                comparison = b.wins - a.wins;
                break;
            case 'losses':
                comparison = a.losses - b.losses;
                break;
            case 'games':
                comparison = b.games - a.games;
                break;
            case 'averageKills':
                comparison = parseFloat(b.averageKills) - parseFloat(a.averageKills);
                break;
            case 'averageDeaths':
                comparison = parseFloat(a.averageDeaths) - parseFloat(b.averageDeaths);
                break;
            case 'averageAssists':
                comparison = parseFloat(b.averageAssists) - parseFloat(a.averageAssists);
                break;
            case 'averageCS':
                comparison = parseFloat(b.averageCS) - parseFloat(a.averageCS);
                break;
            case 'championCount':
                comparison = b.championCount - a.championCount; // 챔피언 수 기준으로 정렬
                break;
            default:
                comparison = 0;
        }

        return ascending ? comparison : -comparison;
    });

    const handleSort = (criteria) => {
        if (sortCriteria === criteria) {
            setAscending(!ascending);
        } else {
            setSortCriteria(criteria);
            setAscending(true);
        }
    };

    const getArrow = (criteria) => {
        if (sortCriteria === criteria) {
            return ascending ? '▼' : '▲';
        }
        return '';
    };

    return (
        <div className="rankComponent">
            <div className="rankComponentTop">
                <h2>통계 및 랭킹</h2>
                <Header></Header>
                <div className="searchBox">
                    <input
                        type="text"
                        placeholder="플레이어 검색 🔍"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>
            <table className="rankingTable">
                <thead>
                    <tr>
                        <th>순위</th>
                        <th>이름</th>
                        {['경기', '승', '패', '승률', 'KDA', '평균킬', '평균데스', '평균어시스트', '킬', '데스', '어시스트', 'CS', '챔피언'].map(criteria => (
                            <th key={criteria} className={criteria}>
                                <button
                                    className={`sort-button-${sortCriteria === criteria ? 'active' : ''}`}
                                    onClick={() => handleSort(criteria)}
                                >
                                    {criteria.charAt(0).toUpperCase() + criteria.slice(1)}
                                    <span className="sort-arrow">{getArrow(criteria)}</span>
                                </button>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((playerData, index) => (
                        <tr
                            key={playerData.player}
                            style={{
                                backgroundColor: searchQuery && playerData.player.toLowerCase().includes(searchQuery.toLowerCase())
                                    ? '#e3efff'
                                    : ''
                            }}
                        >
                            <td>{index + 1}</td>
                            <td>{playerData.player}</td>
                            <td>{playerData.games}</td>
                            <td>{playerData.wins}</td>
                            <td>{playerData.losses}</td>
                            <td>{playerData.winRate}%</td>
                            <td>{playerData.kdaRatio}</td>
                            <td className="avgKills">{playerData.averageKills}</td>
                            <td className="avgDeaths">{playerData.averageDeaths}</td>
                            <td className="avgAssists">{playerData.averageAssists}</td>
                            <td>{playerData.kills}</td>
                            <td>{playerData.deaths}</td>
                            <td className="assists">{playerData.assists}</td>
                            <td>{playerData.averageCS}</td>
                            <td className="championCount">{playerData.championCount}</td> {/* 챔피언 수 추가 */}
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className="rankingTable-mobile">
                <thead>
                    <tr>
                        <th class="sticky-col">순위 | 이름</th>
                        {['경기', '승', '패', '승률', 'KDA', '평균킬', '평균데스', '평균어시스트', '킬', '데스', '어시스트', 'CS', '챔피언'].map(criteria => (
                            <th key={criteria} className={criteria}>
                                <button
                                    className={`sort-button-${sortCriteria === criteria ? 'active' : ''}`}
                                    onClick={() => handleSort(criteria)}
                                >
                                    {criteria.charAt(0).toUpperCase() + criteria.slice(1)}
                                    <span className="sort-arrow">{getArrow(criteria)}</span>
                                </button>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((playerData, index) => (
                        <tr
                            key={playerData.player}
                            style={{
                                backgroundColor: searchQuery && playerData.player.toLowerCase().includes(searchQuery.toLowerCase())
                                    ? '#e3efff'
                                    : ''
                            }}
                        >
                            <td class="sticky-col">{index + 1} | {playerData.player}</td>
                            <td>{playerData.games}</td>
                            <td>{playerData.wins}</td>
                            <td>{playerData.losses}</td>
                            <td>{playerData.winRate}%</td>
                            <td>{playerData.kdaRatio}</td>
                            <td className="avgKills">{playerData.averageKills}</td>
                            <td className="avgDeaths">{playerData.averageDeaths}</td>
                            <td className="avgAssists">{playerData.averageAssists}</td>
                            <td>{playerData.kills}</td>
                            <td>{playerData.deaths}</td>
                            <td className="assists">{playerData.assists}</td>
                            <td>{playerData.averageCS}</td>
                            <td className="championCount">{playerData.championCount}</td> {/* 챔피언 수 추가 */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        
    );
}

export default Ranking;
