import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import './read.css';
import Header from './header';
import { Link } from 'react-router-dom';
import championMapping from "./mapping";

function App() {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "record"));
                const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                const playerData = items.reduce((acc, item) => {
                    const { player, champion, winOrLose, kill, death, assist, gameId } = item;

                    if (!acc[player]) {
                        acc[player] = {
                            totalKills: 0,
                            totalDeaths: 0,
                            totalAssists: 0,
                            totalWins: 0,
                            totalLosses: 0,
                            totalGames: 0,
                            champions: {},
                            recentResults: []
                        };
                    }

                    acc[player].totalKills += parseInt(kill || 0, 10);
                    acc[player].totalDeaths += parseInt(death || 0, 10);
                    acc[player].totalAssists += parseInt(assist || 0, 10);

                    acc[player].totalGames += 1;
                    acc[player].recentResults.push({ gameId, result: winOrLose });

                    if (winOrLose === "win") {
                        acc[player].totalWins += 1;
                    } else {
                        acc[player].totalLosses += 1;
                    }

                    if (!acc[player].champions[champion]) {
                        acc[player].champions[champion] = { win: 0, lose: 0, kills: 0, deaths: 0, assists: 0 };
                    }
                    if (winOrLose === "win") {
                        acc[player].champions[champion].win += 1;
                    } else {
                        acc[player].champions[champion].lose += 1;
                    }

                    acc[player].champions[champion].kills += parseInt(kill || 0, 10);
                    acc[player].champions[champion].deaths += parseInt(death || 0, 10);
                    acc[player].champions[champion].assists += parseInt(assist || 0, 10);

                    return acc;
                }, {});

                const playerChampionWinRate = Object.entries(playerData).map(([player, stats]) => {
                    const kda = {
                        kills: stats.totalKills,
                        deaths: stats.totalDeaths,
                        assists: stats.totalAssists,
                    };

                    const totalGames = stats.totalGames;
                    const winRate = totalGames > 0 ? (stats.totalWins / totalGames) * 100 : 0;

                    const totalKdaRatio = stats.totalDeaths > 0
                        ? ((stats.totalKills + stats.totalAssists) / stats.totalDeaths).toFixed(2)
                        : "Perfect";

                    const championWinRates = Object.entries(stats.champions).map(([champion, record]) => {
                        const championTotalGames = record.win + record.lose;
                        const championWinRate = championTotalGames > 0 ? (record.win / championTotalGames) * 100 : 0;

                        const championKDA = {
                            kills: record.kills || 0,
                            deaths: record.deaths || 0,
                            assists: record.assists || 0,
                        };

                        const kdaRatio = record.deaths > 0
                            ? ((record.kills + record.assists) / record.deaths).toFixed(2)
                            : "Perfect";

                        return {
                            champion,
                            winRate: championWinRate.toFixed(1),
                            win: record.win,
                            lose: record.lose,
                            kda: championKDA,
                            kdaRatio,
                            imageUrl: championMapping[champion]
                                ? `https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[champion]}.png`
                                : 'https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/Unknown.png',
                            championInfo: championMapping[champion]
                                ? `https://www.op.gg/champions/${championMapping[champion]}/build`
                                : 'https://www.op.gg/champions',
                        };
                    });

                    championWinRates.sort((a, b) => {
                        const aTotalGames = a.win + a.lose;
                        const bTotalGames = b.win + b.lose;

                        if (bTotalGames === aTotalGames) {
                            return b.win - a.win;
                        }

                        return bTotalGames - aTotalGames;
                    });

                    stats.recentResults.sort((a, b) => b.gameId - a.gameId);
                    const recentResults = stats.recentResults.slice(0, 10);
                    let streak = 0;
                    let streakType = null;

                    for (const { result } of recentResults) {
                        if (streakType === null) {
                            streakType = result;
                            streak = 1;
                        } else if (streakType === result) {
                            streak += 1;
                        } else {
                            break;
                        }
                    }

                    return {
                        player,
                        totalWins: stats.totalWins,
                        totalLosses: stats.totalLosses,
                        kda,
                        winRate: winRate.toFixed(2),
                        totalKdaRatio,
                        totalGames,
                        championWinRates,
                        streak: `${streak}${streakType === "win" ? "연승" : "연패"} 중`,
                    };
                });

                playerChampionWinRate.sort((a, b) => b.totalGames - a.totalGames);

                setData(playerChampionWinRate);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    const filteredData = searchTerm.trim() === "" 
        ? data 
        : data.filter(playerData => {
            const players = searchTerm.split(',').map(player => player.trim().toLowerCase());
            return players.includes(playerData.player.toLowerCase());
        });

    return (
        <>
            <Header />
            <div className="playerDataSearchBox">
                <input
                    type="text"
                    placeholder="플레이어 이름 검색 (콤마로 구분)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            <div className="playerDataParents">
                {filteredData.length > 0 ? (
                    filteredData.map((playerData) => (
                        <PlayerData key={playerData.player} data={playerData} />
                    ))
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
        </>
    );
}

function PlayerData({ data }) {
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => setShowAll(!showAll);

    const isMobile = window.innerWidth <= 768;
    const maxToShow = isMobile ? 8 : 9;
    const championsToShow = showAll ? data.championWinRates : data.championWinRates.slice(0, maxToShow);

    return (
        <div className="player" id={`player-${data.player}`}>
            <div className="player-info">
                <strong>{data.player}</strong>
                <div className="player-stats">
                    <div className="win-loss">
                        {data.totalWins}승 {data.totalLosses}패 ({data.winRate}%)
                    </div>
                    <div className="kda">
                        K/D/A {data.kda.kills} / {data.kda.deaths} / {data.kda.assists} ({data.totalKdaRatio})
                    </div>
                    <div className="streak">
                        {data.streak}
                    </div>
                </div>
            </div>
            <div className="champion-win-rates">
                {championsToShow.map((championData) => (
                    <div key={championData.champion} className="champion">
                        <div className="cropping">
                            <img src={championData.imageUrl} alt={championData.champion} className="championImg" />
                        </div>
                        <div className="champion-name">
                            <Link to={championData.championInfo} target="_blank">{championData.champion}</Link>
                        </div>
                        <div className="champion-record">
                            {championData.win}승 {championData.lose}패 ({championData.winRate}%)
                        </div>
                        <div className="champion-kda">
                            {championData.kda.kills} / {championData.kda.deaths} / {championData.kda.assists} ({championData.kdaRatio})
                        </div>
                    </div>
                ))}
            </div>
            {data.championWinRates.length > maxToShow && (
                <button onClick={toggleShowAll} className="toggle-button">
                    {showAll ? "▲" : "▼"}
                </button>
            )}
        </div>
    );
}

export default App;
