import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";  // Firebase 설정
import "./nakhoon.css";  // CSS 스타일링
import championMapping from "./mapping";  // 챔피언 맵핑
import Header from "./header";

const ChampionGallery = () => {
  const [playedChampions, setPlayedChampions] = useState({});  // 플레이한 챔피언 데이터를 저장
  const [filter, setFilter] = useState("all");  // 필터 상태

  // Firebase에서 데이터를 가져오는 함수
  useEffect(() => {
    const fetchPlayedChampions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "record"));
        const championsPlayed = {};

        querySnapshot.forEach((doc) => {
          const { player, champion } = doc.data();
          if (player === "낙훈") {  // 낙훈이 플레이한 챔피언만 필터링
            championsPlayed[champion] = true;
          }
        });

        setPlayedChampions(championsPlayed); // 상태에 플레이한 챔피언 저장
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPlayedChampions();
  }, []);  // 컴포넌트가 처음 렌더링될 때 한 번 실행

  const allChampions = Object.keys(championMapping);  // 모든 챔피언 리스트

  // 필터링된 챔피언 리스트
  const filteredChampions = allChampions.filter((championName) => {
    if (filter === "played") {
      return playedChampions[championName]; // 플레이한 챔피언만
    } else if (filter === "unplayed") {
      return !playedChampions[championName]; // 플레이하지 않은 챔피언만
    }
    return true; // 모든 챔피언
  });

   // 플레이한 챔피언 수와 전체 챔피언 수
   const playedChampionCount = Object.keys(playedChampions).length;
   const totalChampionCount = allChampions.length;
   const playedPercentage = ((playedChampionCount / totalChampionCount) * 100).toFixed(2); 

  return (
    <>
    <Header></Header>
    <div className="championGallery">
      {/* 필터 옵션 */}
      
      <div className="filter-options">
        <label>
          <input
            type="radio"
            name="filter"
            value="all"
            checked={filter === "all"}
            onChange={() => setFilter("all")}
          />
          모든 챔피언
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="played"
            checked={filter === "played"}
            onChange={() => setFilter("played")}
          />
          플레이한 챔피언
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="unplayed"
            checked={filter === "unplayed"}
            onChange={() => setFilter("unplayed")}
          />
          플레이하지 않은 챔피언
        </label>
      </div>

      
      <div className="champion-stats">
        <p>{`플레이한 챔피언: ${playedChampionCount} / ${totalChampionCount} (${playedPercentage}%)`}</p>
      </div>
      

      {/* 챔피언 갤러리 */}
      <div className="champion-gallery">
        {filteredChampions.map((championName) => (
          <div key={championName} className="champion">
            <img
              src={`https://ddragon.leagueoflegends.com/cdn/14.22.1/img/champion/${championMapping[championName]}.png`}
              alt={championName}
              className={
                playedChampions[championName]
                  ? "champion-img color"
                  : "champion-img grayscale"
              }
            />
            <div className="champion-name">{championName}</div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default ChampionGallery;
